<template>
  <v-expansion-panel
    v-if="!isNew"
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.formFields.title', locale)}}</v-expansion-panel-header>
    <v-expansion-panel-content
      class="form-fields-list-main"
    >
      <v-alert
        v-if="!allowReservations"
        outlined
        type="warning"
        text
        style="margin: 0 20px;"
      >
        {{$t('booking.formFieldsAllowReservationsMessage', locale)}}
      </v-alert>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
    <form-fields
      v-if="!loading"
      :locale="locale"
      :onChange="handleChangeIsModified"
      :formFields="formFields"
      :pac="paxAsCount"
      :workspaceID="workspaceID"
    />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>


import api from '@/services/api'
import { defaultFormFields } from '@/constants/booking'
import FormFields from '@/components/formFields/Index'
export default {
  components: {
    FormFields,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    editedActivityID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    allowReservations: {
      type: Boolean,
      default: false,
    },
    workspaceID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isFormFieldItemOwner: false,
    showFormFieldItem: false,
    editedFormField: null,
    ownerFormFields: [],
    paxAsCount: false,
    formFields: {
      owner: [],
      pax: [],
    },
    schedules: null,
    count: 0,
    startIndex: 0,
    itemsPerPage: 5,
    search: '',
    loading: false,
    editedScheduleID: null,
  }),
  computed: {
    isNew () {
      return this.editedActivityID === 'new'
    },
    defaultFormFields () {
      return defaultFormFields
    },
  },
  watch: {
    editedActivityID () {
      this.formFields = {
        owner: [],
        pax: [],
      }
      this.handleGetData()
    },
  },
  methods: {
    handleExpand () {
      if (this.editedActivityID) this.handleGetData()
    },
    handleGetData () {
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/private/activities/${this.editedActivityID}/form-fields`)
        .then(response => {
          const aux = response.length > 0 ? JSON.parse(response[0].FormFields) : null
          this.paxAsCount = aux && aux.paxAsCount ? aux.paxAsCount : false

          this.formFields.owner = !aux || !aux.owner ? [] : aux.owner
          this.formFields.pax = !aux || !aux.pax ? [] : aux.pax
          this.ownerFormFields = !aux || !aux.owner ? [] : aux.owner.map(item => item.key)
          this.loading = false
        })
    },
    handleChangeIsModified (v) {
      this.onChange(v)
    },
  },
}
</script>
<style scoped>
.form-fields-list-main {
  padding-top: 15px;
  min-height: 70px;
}
</style>

